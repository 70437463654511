<template>
  <div>
    <q-header reveal class="bg-white text-primary q-py-sm">
      <q-toolbar>
        <router-link :to="{ name: 'Home' }" custom v-slot="{ navigate }">
          <img
            @click="navigate"
            alt="logo"
            class="cursor-pointer"
            style="height: 80px; max-width: 150px"
            src="https://res.cloudinary.com/dlnuo1fcn/image/upload/v1593879613/casa-in-campagna/others/104537819_1439911712877141_6856278696424343970_n-removebg-preview_1_hth38e.png"
          />
        </router-link>
        <q-space />

        <!-- Mobile -->
        <q-btn
          v-if="$q.platform.is.mobile"
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <!-- Desktop -->
        <template v-else>
          <q-btn :to="{ name: 'Home' }" no-caps flat label="Home" />
          <q-btn :to="{ name: 'Camere' }" no-caps flat>{{ t("room") }}</q-btn>
          <q-btn :to="{ name: 'Ristorante' }" no-caps flat>{{
            t("restaurant")
          }}</q-btn>
          <!-- <q-btn :to="{ name: 'Prodotti' }" no-caps flat>{{
            t("products")
          }}</q-btn> -->
          <q-btn :to="{ name: 'Contatti' }" no-caps flat>{{
            t("contacts")
          }}</q-btn>
          <q-btn-dropdown flat round size="md" :icon="`img:${language}.svg`">
            <q-list>
              <q-item clickable @click="changeLanguage()" v-close-popup>
                <q-item-section style="flex-direction: row !important;">
                  <q-icon
                    flat
                    size="sm"
                    :name="`img:${language === 'en' ? 'it' : 'en'}.svg`"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </template>
      </q-toolbar>
    </q-header>

    <!-- Drawer for mobile -->
    <q-drawer
      v-if="$q.platform.is.mobile"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <div class="full-width row  justify-end q-pt-lg">
        <q-btn-dropdown flat round size="md" :icon="`img:${language}.svg`">
          <q-list>
            <q-item :to="{ name: 'Camere' }" v-close-popup>
              <q-item-section style="flex-direction: row !important;">
                <q-icon
                  @click="changeLanguage()"
                  flat
                  size="sm"
                  :name="`img:${language === 'en' ? 'it' : 'en'}.svg`"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
      <q-list>
        <q-item :to="{ name: 'Home' }" clickable>
          <q-item-section>
            <q-item-label>Home</q-item-label>
          </q-item-section>
        </q-item>
        <q-item :to="{ name: 'Camere' }" clickable>
          <q-item-section>
            <q-item-label>{{ t("room") }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item :to="{ name: 'Ristorante' }" clickable>
          <q-item-section>
            <q-item-label>{{ t("restaurant") }}</q-item-label>
          </q-item-section>
        </q-item>
        <!-- <q-item :to="{ name: 'Prodotti' }" clickable>
          <q-item-section>
            <q-item-label>{{ t("products") }}</q-item-label>
          </q-item-section>
        </q-item> -->
        <q-item :to="{ name: 'Contatti' }" clickable>
          <q-item-section>
            <q-item-label>{{ t("contacts") }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
  </div>
</template>

<i18n>
{
  "en": {
    "room": "Our rooms",
    "restaurant": "Restaurant",
    "products": "Our products",
    "contacts": "Contacts"
  },
  "it": {
    "room": "Le nostre camere",
    "restaurant": "Ristorante",
    "products": "I nostri prodotti",
    "contacts": "Contatti"
  }
}
</i18n>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NavBarComponent",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          room: "Our rooms",
          restaurant: "Restaurant",
          products: "Our products",
          contacts: "Contacts",
        },
        it: {
          room: "Le nostre camere",
          restaurant: "Ristorante",
          products: "I nostri prodotti",
          contacts: "Contatti",
        },
      },
    });
    return {
      t,
      leftDrawerOpen: ref(false),
      language: ref("it"),
    };
  },
  methods: {
    changeLanguage(): void {
      if (this.language === "it") {
        this.language = "en";
        this.$i18n.locale = "en";
      } else {
        this.language = "it";
        this.$i18n.locale = "it";
      }
    },
  },
});
</script>
