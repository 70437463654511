import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import quasarUserOptions from './quasar-user-options'
import "@/styles/app.scss"
import { createI18n } from 'vue-i18n'
import VueEasyLightbox from 'vue-easy-lightbox'

const i18n = createI18n({
    legacy: false, 
    locale: "it",
    fallbackLocale: 'it',
    globalInjection: true, 
  });

createApp(App)
.use(i18n)
.use(VueEasyLightbox)
.use(Quasar, quasarUserOptions)
.use(router)
.use(createMetaManager())
.mount('#app')
