
import { useMeta } from "vue-meta";
import { ref, defineComponent } from "vue";
import NavBarComponent from "@/components/NavBarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

// eslint-disable-next-line no-undef
export default defineComponent({
  name: "LayoutDefault",
  components: { NavBarComponent, FooterComponent },
  setup() {
    useMeta({
      title: "",
      description: `La Casa in Campagna nasce da un sogno, rendere Grande la Semplicità. La passione e la fantasia di creare dei piatti che racchiudono sapori e odori tipici del basso molise, cibi nutrienti e genuini che madre terra sa regalarci, succedere con sentimento una tradizione culinaria tramandata da genitori a figli.`,
      htmlAttrs: { lang: "it", amp: true },
    });

    return {
      leftDrawerOpen: ref(false),
    };
  },
});
