<template>
  <div>
    <q-footer elevated class="bg-primary text-white">
      <q-toolbar>
        <q-toolbar-title>
          <div class="text-subtitle2">
            {{ new Date().getFullYear() }} — ©
            <span v-if="!$q.platform.is.mobile">La casa in campagna</span>
          </div>
        </q-toolbar-title>

        <q-space v-if="!$q.platform.is.mobile" />

        <q-toolbar-title shrink class="flex justify-end">
          <div class="text-subtitle2">
            luciaingiuseppe@gmail.com
          </div>
        </q-toolbar-title>
      </q-toolbar>
    </q-footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
