import { render } from "./NavBarComponent.vue?vue&type=template&id=7d364e66"
import script from "./NavBarComponent.vue?vue&type=script&lang=ts"
export * from "./NavBarComponent.vue?vue&type=script&lang=ts"
script.render = render
/* custom blocks */
import block0 from "./NavBarComponent.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QSpace,QBtn,QBtnDropdown,QList,QItem,QItemSection,QIcon,QDrawer,QItemLabel});qInstall(script, 'directives', {ClosePopup});
