
import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Dialog,
    AppFullscreen,
    Notify
  }
}