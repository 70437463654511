import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const Home = () => import('../views/Home.vue')
const Contatti = () => import('../views/Contatti.vue')
const INostriProdotti = () => import('../views/INostriProdotti.vue')
const Ristorante = () => import('../views/Ristorante.vue')
const Camere = () => import('../views/Camere.vue')
const Privacy = () => import('../views/Privacy.vue')
const NotFound = () => import('../views/NotFound.vue')


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: Contatti,
  },
  {
    path: '/prodotti',
    name: 'Prodotti',
    component: INostriProdotti
  },
  {
    path: '/ristorante',
    name: 'Ristorante',
    component: Ristorante
  },
  {
    path: '/camere',
    name: 'Camere',
    component: Camere
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
