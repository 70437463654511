
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NavBarComponent",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          room: "Our rooms",
          restaurant: "Restaurant",
          products: "Our products",
          contacts: "Contacts",
        },
        it: {
          room: "Le nostre camere",
          restaurant: "Ristorante",
          products: "I nostri prodotti",
          contacts: "Contatti",
        },
      },
    });
    return {
      t,
      leftDrawerOpen: ref(false),
      language: ref("it"),
    };
  },
  methods: {
    changeLanguage(): void {
      if (this.language === "it") {
        this.language = "en";
        this.$i18n.locale = "en";
      } else {
        this.language = "it";
        this.$i18n.locale = "it";
      }
    },
  },
});
