import { render } from "./FooterComponent.vue?vue&type=template&id=caab2e68"
import script from "./FooterComponent.vue?vue&type=script&lang=js"
export * from "./FooterComponent.vue?vue&type=script&lang=js"
script.render = render

export default script
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QFooter,QToolbar,QToolbarTitle,QSpace});
